.fontsize {

    font-size: smaller;
    color: aqua;

}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 35px;
    height: 35px;
    color: #989a9b;
    border: 1px solid white;
}

.cboder {
    border: 2px solid #989a9b;
    margin-bottom: 5px;
}
.width100{
    width: 100%;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #607D8B;
    background: #607D8B;
}